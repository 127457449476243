* {
  box-sizing: border-box;

}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Markazi Text', serif;
  font-family: 'Karla', sans-serif;
  overflow-x: hidden;

}



header,
footer,
.hero-section,
.specials-section,
.testimonials-section,
.aboutus-section {
  width: 100%;
}

.testimonials-section {
  padding: 2em;
}

.hero-container,
.specials-section,
.testimonials-container,
.aboutus-container,
.footer-container {
  margin: 0 auto;
  padding: 2em;
  max-width: 60em;
}



footer,
.hero-section,
.specials-section,
.testimonials-section,
.aboutus-section {
  height: fit-content;
  /* padding: 2em; */


}



/* HAMBURGER MENU CSS */

.anchor-nav {
  position: sticky;
  top: 0;
  z-index: 12;
  
}

header {
  background-color: white;
  padding: 1em 2em;
  position: relative;
}

.primary-nav {
  margin: 0 auto;
  max-width: 60em;
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.logo-nav {
  width: 178px;
}

.hamburger{
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  cursor: pointer;
}

.burger{
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.primary-nav ul {
  margin: 0;
  padding: 2em 0.5em;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 84.7px;
  right: -100%;
  opacity: 0;
  transition: all 0.3s linear;
  background-color: #F4CE14;

}

.primary-nav ul.visible {
  transform: translateX(100%);
  opacity : 1;
  top: 84.7px;
  right: 100%;

}


.primary-nav ul li {
  list-style: none;
  /* border-bottom: solid black 1px; */

}

.primary-nav ul li a {
  display: block;
  width: 100%;
  padding: 0.75em;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.25rem;
  color: black;
  background-color: #F4CE14;
  border-bottom: solid black 1px;
  overflow: hidden;
}

.primary-nav ul li a:not(.active):hover,
.primary-nav .active {
  background-color:  #495E57;
  color:   #F4CE14;
}

/* END HAMBURGER MENU CSS */


  .hero-section {
    background-color: #495E57;
    height: fit-content;
    display: flex;
    align-items: center;
  }

  .hero-container {
    margin: 0 auto;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1.5em;

  }

  .cta-reserve-table {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    width: 200px;
    height: 40px;
    border-radius: 16px;
    border: none;
    background-color: #F4CE14;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  .hero-text-container {
    color: white;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    gap: 1.5em;
    font-weight: 400;
    font-size: 20px;
  }

  .hero-text-container h1 {
    margin: 0 0 0 0;
    padding: 0;
    color: #F4CE14;
    font-family: 'Markazi Text';
    line-height: 0.8;
    font-weight: bold;
    font-size: 64px;
  }

  .hero-text-container h3 {
    margin-top: 0;
    font-family: 'Markazi Text';
    font-weight: 400;
    font-size: 32px;
  }

  .hero-photo {
    width: 100%;
    height: 424px;
    border-radius: 16px;
    object-fit: cover;
  }


  .specials-section {
    /* padding: 2em 0.5em; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  
  .specials-section-title {
    margin: 0 0 2em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
  
  
  }
  
  .specials-title {
    text-align: center;
    margin: 0;
    font-family: 'Markazi Text';
    line-height: 0.8;
    font-weight: 700;
    font-size: 4rem;
  }
  
  a.cta-online-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    margin: 0;
    width: 200px;
    height: 40px;
    border-radius: 16px;
    border: none;
    background-color: #F4CE14;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* .specials-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1.85em;
  }
  
  .specials-card {
    background-color: #EDEFEE;
    width: 300px;
    height: 500px;
    border: solid 1px black;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .dish-info {
    padding: 1.5em;
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .dish-photo {
    width: 100%;
    height: 200px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    object-fit: cover;

  } */

  .specials-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1.85em;
  }

  .specials-card {
    background-color: #EDEFEE;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    border-radius: 1em;
  }
  
  .dish-info {
    padding: 1.5em;
    width: 60%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .dish-photo {
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    order: 2;

  }
  
  .dish-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dish-title,
  .dish-price {
    margin: 0;
  }
  
  .dish-title {
    font-family: 'Markazi Text';
    font-weight: 500;
    font-size: 1.5rem;
  }
  
  .dish-price {
    font-weight: bold;
    color: #EE9972;
    font-size: 1rem;
  }

  .dish-description {
    width: 100%;
    height: 3.6em;
    /* white-space: nowrap; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
  }
  
  .delivery-link {
    display: block;
    color: black;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
  }
  
  .delivery-icon {
    margin-left: 1em;
    font-weight: 700;
    font-size: 1rem;
  }


  
  .testimonials-section {
    height: fit-content;
    background-color: #495E57;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
  
  .testimonials-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 1.5em;
  }
  
  .testimonials-section h2 {
    margin: 0;
    color: white;
    font-family: 'Markazi Text';
    font-weight: 700;
    font-size: 64px;
    line-height: 0.8;
  }
  
  .testimonial-card {
    background-color: white;
    padding: 10px;
    width: 220px;
    height: 374px;
    border-radius: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .testimonial-card h4 {
    margin: 10px 0;
    font-weight: 600;
    font-size: 20px;
  }
  
  .guest-photo {
    width: 128px;
    height: 128px;
    border-radius: 50%;
  }
  
  .stars-rating {
    margin: 10px 0;
  }
  
  .testimonial-card p {
    margin-bottom: 10px;
  }

  .aboutus-section {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .aboutus-section {
    /* height: 578px; */
    display: flex;
    align-items: center;
    height: fit-content;
  
  }

  .sub-header {
    background-color: #495E57;
    width: 100%;
    height: fit-content;
    padding: 0.5em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #F4CE14;
  }

   .sub-header h1 {
    margin: 0;
    font-family: 'Markazi Text', serif;
    font-weight: 600;
    line-height: 0.8;
    color: #F4CE14;
    font-size: 3.5em;
    text-align: center;
  }

  .sub-header p {
    margin: 0;
    font-size: 0.8em;
    font-weight: 400;
    color: white;
    width: 236px;
    text-align: center;
  }

  .aboutus-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .aboutus-container h2,
  .aboutus-container p {
    text-align: left;
    width: 100%;
  }

  .aboutus-container h2 {
    margin: 0;
    font-family: 'Markazi Text';
    font-weight: 700;
    font-size: 64px;
    line-height: 0.8;
  }

  .aboutus-container h4 {
    margin: 0;
    font-family: 'Markazi Text';
    font-weight: 600;
    font-size: 32px;
  }

  .aboutus-container p {
    margin: 50px 0;
    font-size: 20px;
  }

  .aboutus-images-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1.5em;

  }
  .photo-container-1 {
    width: 100%;
    position: unset;
  }
  
  .photo-container-2 {
    width: 100%;
    position: unset;
  }
  
  .photo-chefs-b,
  .photo-chefs-a {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  footer {
    background-color: #495E57;
  }

  .footer-container {
    color: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 2em;
  }
  
  .reserve-style {
    background-color: white;
    color: #495E57;
  }

  .logo-footer {
    width: 143px;
  }
  
  .footer-container ul {
    list-style: none;
    padding: 0;
  }

  .footer-container li {
    margin: 0.5em 0;
  }
  
  .footer-container a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.250rem;
  }

  .contact-info li {
    font-weight: 500;
    font-size: 1.250rem;
  }

  .footer-container h4 {
    margin: 1em 0 0.5em 0;
    font-weight: 700;
    font-size: 1.5rem;
  }


  .footer-container.reserve-style a {
    color: #495E57;
  }

  .socialmedia-links,
  .socialmedia-links ul {
    display: flex;
    margin: 0;
   }
  .socialmedia-links {
    flex-direction: column;

  }

  .socialmedia-links ul {
    gap: 2em;
  }

  .socialmedia-links li {
    margin: 0;
  }

  /* FORM SECTION FOR MOBILE DEVICES STARTS HERE */

input {
  border: none;
}

  span{
    font-size: 0.9rem;
    padding: 3px;
    color: white;
    display: none;
}

  .errormessage {
    font-size: 0.9rem;
    margin: 0;
    padding: 3px;
    color: white;
  }

  /* input:invalid[focused="true"]{
      border: 2px solid red;
  }

  input:invalid[focused="true"] ~ span{
      display: block;
  } */

  .invalid {
    border: 2px solid red;
    color: red;
  }

  .btn-continue {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
    width: 200px;
    height: 40px;
    border-radius: 16px;
    border: none;
    background-color: #F4CE14;
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  .btn-continue:active {
    background-color: #495E57;
    color: white;
    border: 1px solid #F4CE14;
  }

  a.btn-continue.back:active {
    background-color: #F4CE14;
    color: black;
  }

  #btn-continue-disabled,
  .btn-continue.disabled {
    width: 100%;
    background-color: grey;
    color: white;
    border: 1px solid #F4CE14;
  }

  a.btn-continue.back {
    background-color: #495E57;
    border: 1px solid #F4CE14;
    color: white;
    margin-top: 2em;
  }

  .form-confirmation-section {
    background-color: #495E57;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .form-section {
    background-color: #495E57;
    margin: 0 auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .form-section h2 {
    margin-top: 0;
    text-align: center;
  }

  .form-container,
  .form-container-contact,
  .form-container-review,
  .confirmation-container {
    background-color: #495E57;
    padding: 2em;
    width: 100%;
    max-width: 448px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.3em;
    color: #F4CE14;
  }

  .form-instructions {
    display: none;
  }

  .form-body {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0.5em;
    grid-template-areas: 
            "date date"
            "time time"
            "diners diners"
            "location location"
            "occasion occasion"
            "special special"
            "continue continue";
  }

  
  #date,
  #reserved-time,
  #diners,
  #table-location,
  #special-occasion,
  #special-request,
  #fname,
  #lname,
  #email,
  #phone {
    width: 100%;
    border-radius: 0.6em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1rem;
    font-weight: 500;

  }

  #special-request {
    height: auto;
    resize: none;
  }


  #date,
  #reserved-time,
  #diners,
  #table-location,
  #special-occasion,
  #fname,
  #lname,
  #email,
  #phone {
    height: 40px;
  }

 
  #date,
  #reserved-time,
  #diners,
  #table-location,
  #special-occasion,
  #fname,
  #lname,
  #email,
  #phone,
  #special-request  {
    padding: 0 2em;
    background-color: white;
  }

  #label-date {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-area: date;

  }
  
  #reserved-time,
  #label-time {
    grid-area: time;
  }

  #diners,
  #label-diners {
    grid-area: diners;
  }
  
  #table-location,
  #label-table {
    grid-area: location;
  }
  
  #special-occasion,
  #label-occasion {
    grid-area: occasion;
  }

  #special-request,
  #label-special {
    grid-area: special;
    width: 100%;
  }

  .btns-container.one {
    grid-area: continue;
  }

  .btn-continue.one {
    width: 100%;
    margin: 0;
  }

  /* FORM CONTACT INFO  */

  .form-instructions-contact p {
    display: none;
  }

  .form-body-contact {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0.5em;
    grid-template-areas: 
            "fname fname"
            "lname lname"
            "email email"
            "phone phone"
            "conftitle conftitle"
            "emailop textop"
            "continuetwo continuetwo";
  }

  #fname,
  #label-fname {
    grid-area: fname;
  }

  #lname,
  #label-lname {
    grid-area: lname;
  }

  #email,
  #label-email {
    grid-area: email;
  }

  #phone,
  #label-phone {
    grid-area: phone;
  }

  .contact-confirmation-title {
    grid-area: conftitle;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  #email-option {
    grid-area: emailop;
  }

  #text-option {
    grid-area: textop;
  }

  .btns-container.contact {
    grid-area: continuetwo;
    margin-top: 1.312em;

  }

  .btn-continue.contact,
  .btn-continue.back {
    width: 100%;
  }

  .btn-continue.contact {
    margin: 0 0 2em 0;
  }

/* END OF FORM CONTACT INFO */


/* FORM REVIEW INFO */

.form-body-review {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0.75em;
}


.form-container-review h4,
.form-container-review p {
  margin: 0;
}
.form-container-review h4 {
  font-size: 1.2em;
  font-weight: 700;
  color: #F4CE14;
}

.form-container-review p {
  color: white;
  font-size: 1em;
  font-weight: 600;
}

.form-container-review .p-review {
  color: white;
  width: 100%;
  font-size: 1.2em;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  
}

.form-confirmation-options {
  color: white;
  width: 100%;
}

.form-confirmation-options h4 {
  color: white;
  font-size: 1.2em;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.btns-container-review {
  margin-top: 1.312em;;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 13;
  grid-row-end: 14;
}

.btn-continue.review {
  margin: 2.2px 0 2em 0;
  width: 100%;
}

/* END OF FORM REVIEW INFO */


/* FORM CONFIRMATION */

.conf-message-container {
  background-color: white;
  padding: 1em;
  border-radius: 1em;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 2.575em;
}

.conf-message-container img {
  justify-self: center;
}

.confirmation-container h2,
.confirmation-container p {
  margin: 0;
  color: black;
}

.confirmation-container p {
  font-size: 1.5rem;
  font-weight: 600;
}

.btns-container-confirmation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2em 0 0 0;
}

a.btn-continue.confirmation {
  width: 100%;
}

/* END OF FORM CONFIRMATION */


/* FORM SECTION FOR MOBILE DEVICES ENDS HERE */
  


/* NOT FOUND AND UNDER CONSTRUCTION PAGES CSS */
  .not-found-container {
  width: 100%;
  height: 37em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.not-found-container h2,
.not-found-container p {
  color: #495E57;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}

.not-found-container p {
  margin-top: 0;
}

.fa-person-digging{
  color: #495E57;
}

/* END OF NOT FOUND AND UNDER CONSTRUCTION PAGES CSS */


@media (min-width: 386px) { 

  .logo-nav {
    width: 178px;
  }

  .primary-nav ul {
    top: 84.7px;
    right: -100%;

  }
  
  .primary-nav ul.visible {
    top: 84.7px;
    right: 100%;
  }

  .sub-header {
    justify-content: center;
    border-bottom: 1px solid #F4CE14;

  }

  .sub-header h1 {
    text-align: center;
    font-size: 3.5em;
  }

  .sub-header p {
    text-align: center;
  }


  /* FORM FIRST PAGE */

  .form-body {
    grid-template-areas: 
            "date time"
            "diners diners"
            "location location"
            "occasion occasion"
            "special special"
            "continue continue";
  }

  .btns-container.one {
    margin: 1.009em 0 0 0;
  }

  /*  END OF FORM FIRST PAGE */


}


  @media (min-width: 560px) {

    .logo-nav {
      width: 258px;
    }

    .primary-nav ul {
      width: 50%;
      height: 100vh;
      padding: 7em 1em 1em 1em;
      background-color: #F4CE14;

      top: 0;
      right: -100%;
    }
    
    .primary-nav ul.visible {

      top: 0;
      right: 50%;
    }

    .hero-container {
      grid-template-columns: repeat(2, 1fr);
  
    }

    .specials-section {
      padding: 2em;
    }

    .specials-section-title {
      margin: 0 0 2em 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2em;
    
    
    }
    
    .specials-grid-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 1.85em;
    }

    .specials-card {
      background-color: #EDEFEE;
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: row;
      border-radius: 1em;
    }
    
    .dish-info {
      padding: 1.5em;
      width: 60%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .dish-photo {
      width: 40%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
      order: 2;
    }

    .testimonials-grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: auto;
      gap: 1.5em;
    }

    .aboutus-images-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .footer-container {
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
      justify-content: center;
    }

     .footer-container h4 {
      margin-top: 0;
    }



    /* FORM PAGES */

    .form-section,
    .form-confirmation-section {
      background-color: white;
    }

    .form-container,
    .form-container-contact,
    .form-container-review,
    .confirmation-container {
      background-color: #495E57;
      margin: 2em 0;
      padding: 2em;
      border-radius: 1em;
      width: 80%;
      max-width: 600px;
    }

    .btns-container-confirmation {
      max-width: 510px;
    }

    .btn-continue.confirmation {
      max-width: 200px;
    }

    /* END OF FORM PAGES */


  }

  @media (min-width: 768px) {

    .hamburger {
      display: none;
    }


    .primary-nav {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .logo-nav {
      width: 258px;

    }

    .primary-nav ul {
      position: unset;
      width: 100%;
      height: fit-content;
      opacity: 1;
      padding: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      gap: 1em;

    }

    .primary-nav ul.visible {
      transform: none;

    }

    .primary-nav ul li {
      background-color: transparent;
    }

    .primary-nav ul li a {
      color: black;
      background-color: transparent;
      border-radius: 1em;
      border: none;
      font-size: 1.1em;
    }

    .primary-nav ul li a:not(.active):hover,
    .primary-nav .active {
      background-color:  #495E57;
      color:   #F4CE14;
    }

    .specials-grid-container {
      width: fit-content;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 1.85em;
    }

    .specials-card {
      background-color: #EDEFEE;
      max-width: 336.2px;
      height: 500px;
      border: solid 1px black;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .dish-info {
      padding: 1.5em;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .dish-photo {
      width: 100%;
      height: 200px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      object-fit: cover;
      order: -1;
  
    }

    .testimonials-grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      gap: 1.5em;
    }


    /*  FORM PAGES */

    .form-container,
    .form-container-contact,
    .form-container-review,
    .confirmation-container {
      /* height: 610px; */
      height: fit-content;
      padding: 2em 4.5em;

    }

    .form-body,
    .form-body-contact,
    .form-body-review {
      height: fit-content;
    }

    a.btn-continue.back {
      margin: 0;
      width: 200px;
      order: 0;
    }

    .btns-container.one {
      display: flex;
      justify-content: center;
    }

    a.btn-continue.one {
      width: 200px;
    }

    .btns-container.contact,
    .btns-container-review {
      display: flex;
      justify-content: space-between;
    }

    a.btn-continue.contact,
    .btn-continue.contact,
    .btn-continue.review {
      margin: 0;
      width: 200px;
      order: 2;

    }
    #btn-continue-disabled,
    .btn-continue.disabled {
      margin: 0;
      width: 200px;
      order: 2;
    }


    .form-body-review {
      width: 100%;
    }



  /* END FORM PAGES */

   }

   @media (min-width: 1000px) {
    footer,
    .hero-section,
    .specials-section,
    .testimonials-section,
    .aboutus-section,
    .hero-container,
    .aboutus-container,
    .footer-container {
      padding: 0;

    }

   
    .primary-nav {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .primary-nav ul {
      position: unset;
      width: max-content;
      height: fit-content;
      opacity: 1;
      padding: 0;
      background-color: transparent;
      display: flex;
      justify-content: space-evenly;
      gap: 1em;

    }

    .hero-section {
      background-color: #495E57;
      height: 406px;
      display: flex;
      align-items: center;
    
    }

    .hero-container {
      margin: 0 auto;
      height: 346px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;

    }
    
    .cta-reserve-table {
      align-self: flex-start;
      width: 200px;
      height: 40px;
      border-radius: 16px;
      border: none;
      background-color: #F4CE14;
      font-family: 'Karla', sans-serif;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    
    }
    
    .hero-text-container {
      text-align: left;
      width: 422px;
      height: 100%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    
    }
    
    .hero-text-container h1 {
      margin: 0 0 0 0;
      padding: 0;
      color: #F4CE14;
      font-family: 'Markazi Text';
      line-height: 0.8;
      font-weight: bold;
      font-size: 64px;
    }
    
    .hero-text-container h3 {
      margin-top: 0;
      font-family: 'Markazi Text';
      font-weight: 400;
      font-size: 32px;
    }
    
    .hero-text-container {
      font-weight: 400;
      font-size: 20px;
    }
    
    .hero-photo {
      /* width: 100%; */
      width: 400px;
      height: 424px;
      border-radius: 16px;
      object-fit: cov;
      position: absolute;
      top: 0;
      right: 0;
    }
    
    .specials-section {
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .specials-section-title {
      margin: 5em 0 2em 0;
      width: 60em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    
    .specials-title {
      margin: 0;
      font-family: 'Markazi Text';
      line-height: 0.8;
      font-weight: 700;
      font-size: 4rem;
    }
    
    .cta-online-menu {
      display: block;
      margin: 0;
      width: 200px;
      height: 40px;
      border-radius: 16px;
      border: none;
      background-color: #F4CE14;
      font-family: 'Karla', sans-serif;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }
    
    .specials-grid-container {
      width: 100%;
      margin-bottom: 4em;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      gap: 1em;
    }
    
    .specials-card {
      background-color: #EDEFEE;
      width: 100%;
      height: 500px;
      border: solid 1px black;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .dish-info {
      padding: 1.5em;
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    
    .dish-photo {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;
      border-bottom-right-radius: unset;
      order: -1;
    }
    
    .dish-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .dish-title,
    .dish-price {
      margin: 0;
    }
    
    .dish-title {
      font-family: 'Markazi Text';
      font-weight: 500;
      font-size: 1.5rem;
    }
    
    .dish-price {
      font-weight: bold;
      color: #EE9972;
      font-size: 1rem;
    }
    
    .delivery-link {
      display: block;
      color: black;
      font-size: 1rem;
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;
    }
    
    .delivery-icon {
      margin-left: 1em;
      font-weight: 700;
      font-size: 1rem;
    }
    
    .testimonials-section {
      height: 590px;
      background-color: #495E57;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 60px;
    }
    
    .testimonials-section h2 {
      margin: 0;
      color: white;
      font-family: 'Markazi Text';
      font-weight: 700;
      font-size: 64px;
      line-height: 0.8;
    }
    
    .testimonials-grid-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      gap: 1.5em;
    }
    
    .testimonial-card {
      background-color: white;
      padding: 10px;
      width: 220px;
      height: 374px;
      border-radius: 16px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    
    }
    
    .testimonial-card h4 {
      margin: 10px 0;
      font-weight: 600;
      font-size: 20px;
    }
    
    .guest-photo {
      width: 128px;
      height: 128px;
      border-radius: 50%;
    }
    
    .stars-rating {
      margin: 10px 0;
    
    }
    
    .testimonial-card p {
      margin-bottom: 10px;
    }
    
    .aboutus-container {
      height: 32.375em;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin: 2em 0;
    }

    .aboutus-container h2,
    .aboutus-container p {
      width: 422px;
    }
    
    .aboutus-container h2 {
      margin: 0;
      font-family: 'Markazi Text';
      font-weight: 700;
      font-size: 64px;
      line-height: 0.8;
    }
    
    .aboutus-container h4 {
      margin: 0;
      font-family: 'Markazi Text';
      font-weight: 600;
      font-size: 32px;
    }
    
    .aboutus-container p {
      margin-top: 50px;
      margin-bottom: 0;
      font-size: 20px;
    }
    
    .photo-container-1 {
      width: 272px;
      height: 320px;
      position: absolute;
      right: 0;
      top: 0;
    
    }
    
    .photo-container-2 {
      width: 272px;
      height: 320px;
      position: absolute;
      right: 200px;
      top: 198px;
      z-index: -1;
    
    }
    
    .photo-chefs-b,
    .photo-chefs-a {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    
    footer {
      background-color: #495E57;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
    
    }
    
    .footer-container {
      color: white;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      gap: 1.5em;
      /* align-items: center; */
    }
    
    .logo-footer {
      width: 173px;
    }
    
    .footer-container ul {
      list-style: none;
      padding: 0;
    }
    
    .footer-container a {
      color: white;
      text-decoration: none;
      font-weight: 500;
      font-size: 20px;
    }

   }